import React from 'react';

import Layout from '../components/Layout';

import { Link } from 'gatsby';
import Header from '../components/Header';
import Footer from '../components/Footer';

import pic3 from '../assets/images/pic03.jpg';

const IndexPage = () => (
  <Layout>
    <Header />

    <div id="main">
      <header className="major container medium">
        <h2>We visited a workshop</h2>        
      </header>

      <div className="box container">
        <section>
          <p>            
              <img src={pic3} alt="" width="100%"/>            
          </p>
          <blockquote>
            On our stay over in Hong Kong we were out looking for a place to stay and set up our office, 
            the one we have now is too small, and on walking up the hills we where all of a sudden in car 
            repair valley. 5-10 small workshops, each the size of a small garage and each with their own 
            specialty. No room for any big American cars and I would have trouble in getting around in the 
            workshop without bashing my head, but what we saw, was happy people and customers at ease. 
            Taxi’s coming in and going out again. Dents, overhaul, paint shop they have it. On top a small 
            village of people buzzing around their normal lives, most not owning a car, for in Hong Kong you 
            on’t need one. Easier to take the tram 2 HKG$, bus or subway about 7 HGK$ going from North Point 
            to Wan Chai on Hong Kong Island. Flag down on a taxi 18 HKG$ about 3 US$ and the same trip as 
            before about 30 HKG$. If you have a car, you have to pay 1.500 HKG$ a month in North Point for 
            a bed for the car.
          </blockquote>
          <blockquote>
            In Hong Kong, you find all the nicest cars from around the world, but for car, collectors note they 
            are right hand stirred like the rest of any former UK territory. And yes the old Toyota Crown still 
            lives as Taxi’s here in Hong Kong, but they are all new, nice and run on LPG
          </blockquote>          
        </section>
        <section>
          <header>
            <h3>Blog list</h3>
          </header>
          <ul className="default">
            <li>
              <Link to="/we-visited-a-vulcanizing-shop-in-panay">WE VISITED A VULCANIZING SHOP IN PANAY</Link>
            </li>
            <li>
              <Link to="/we-visited-a-car-dealer">WE VISITED A CAR DEALER</Link>
            </li>
            <li>
              <Link to="/we-visited-a-workshop">WE VISITED A WORKSHOP</Link>
            </li>           
          </ul>
          <p><Link to="/">Back to Home Page</Link></p>
        </section>
      </div>

      <footer className="major container medium">
        <h3>Contact Us for more information</h3>
        <p>
          Car collectors choice aim to share the knowledge and experience in an online world. We offer design and build services for you to have an idea.
          Feel free to contact us and we will get back to you as soon as it possible.
        </p>
        <ul className="actions special">
          <li>
            <a href="mailto:support@sal-tech.com?Subject=Carcollectorschoice%20Inquiry" className="button" target="_top">
              Contact Us
            </a>
          </li>
        </ul>
      </footer>
    </div>
    <Footer />
  </Layout>
);

export default IndexPage;
